import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from 'react-router-dom';


const SlpitOne = () => {
    return (
            <div className="rn-splite-style">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail image-left-content">
                                    <img src="./images/service/s8.jpg" alt="split Images" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="title">What is Strategic Edge Consultants?</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">Strategic Edge is a management consulting, technology services and outsourcing company operating across the globe. As a leading technology services and outsourcing partner for medium and large global customers, we leverage technology and our flexible delivery models to drive performance and business value throughout a customer's enterprise.

Our comprehensive service approach incorporates a holistic view of our customers that extends beyond delivery. This approach integrates enterprise solutions and services, proprietary best practice offerings, strategic application and maintenance outsourcing, as well as consulting and integration services.

We as team strive to deliver the best value to our clients through a combination of highly experienced workforce, defined processes, state-of-art technologies and client management solutions. Our commitment towards our work and clients keeps us ahead of market competition .</p>
                                    </ScrollAnimation>

                                    
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <ul className="split-list">
                                            <li>- Design Prototyping</li>
                                            <li>- Customer First</li>
                                            <li>- Technology</li>
                                            <li>- Ethics & Values</li>
                                        </ul>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                            <Link className="btn-default" to="./contact">
                                            Contact With Us
                                            </Link>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SlpitOne
